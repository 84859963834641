import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Container,
  // CircularProgress,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';

import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import { getProductSnapshotsPerPage } from '../../../requests/api/productsnapshots';
import { getAllCategoriesAndSubCategories, getAllCategoriesAndSubCategoriesByUserId } from '../../../requests/api/categories';
import { getAllSupermarkets } from '../../../requests/api/supermarkets';
import { getAllBrands } from '../../../requests/api/brands';

import alphabeticSort from '../../../utils/alphabeticSort';
import useStyles from './styles';

import {
  setQuery,
  setCategory,
  setSubcategory,
  setOrder,
  setStartDate,
  setEndDate,
  setPage,
  setLimit,
  setSupermarket,
  setBrand,
} from '../../../actions/snapsList';

const sortOptions = [
  {
    value: 'DESC',
    label: 'Más nuevo primero',
  },
  {
    value: 'ASC',
    label: 'Más antiguo primero',
  },
];

function ProductsnapshotListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [count, setCount] = useState(0);
  const [productsnapshots, setProductsnapshots] = useState([]);
  const [brands, setBrands] = useState([]);
  const [supermarkets, setSupermarkets] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [originalCategories, setOriginalCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    query,
    order,
    category,
    supermarket,
    subcategory,
    page,
    limit,
    startDate,
    endDate,
    brand,
  } = useSelector((state) => state.snapsList);

  const account = useSelector((state) => state.account);

  const dispatch = useDispatch();

  const cleanFilters = () => {
    const today = new Date();
    dispatch(setEndDate(today.setHours(23, 59, 59, 999)));
    today.setDate(today.getDate() - 7);
    dispatch(setStartDate(today.setHours(0, 0, 0, 0)));
    dispatch(setPage(1));
    dispatch(setLimit(10));
    dispatch(setQuery(''));
    dispatch(setSupermarket([]));
    dispatch(setCategory([]));
    dispatch(setSubcategory([]));
    dispatch(setBrand([]));
    dispatch(setOrder('DESC'));
  };

  useEffect(() => {
    const getBrands = async () => {
      const response = await getAllBrands();
      if (response.success) {
        const data = await response?.data?.data;
        setBrands(data.sort((a, b) => alphabeticSort(a, b)));
      } else {
        enqueueSnackbar('Error al cargar la información de las categorías.', {
          variant: 'error',
        });
      }
    };

    const getSupermarkets = async () => {
      const response = await getAllSupermarkets();
      if (response.success) {
        const data = await response?.data?.data;
        setSupermarkets(data.sort((a, b) => alphabeticSort(a, b)));
      } else {
        enqueueSnackbar('Error al cargar la información de las categorías.', {
          variant: 'error',
        });
      }
    };
    cleanFilters();
    getSupermarkets();
    getBrands();
  }, []);

  useEffect(() => {
    try {
      const getCategories = async () => {
        let response;
        if (account.user.role === 'external' && account.user.id) {
          response = await getAllCategoriesAndSubCategoriesByUserId(account.user.id);
        } else {
          response = await getAllCategoriesAndSubCategories();
        }
        if (response.success) {
          const data = await response?.data?.data;
          setOriginalCategories(data.sort((a, b) => alphabeticSort(a, b)));
          let subcatList = [];
          let filteredCats = [];
          if (supermarket.length > 0) {
            const ides = supermarket.map((sup) => sup.id);
            filteredCats = data.filter(
              (item) => ides.includes(item.supermarket_id),
            ).sort(
              (a, b) => alphabeticSort(a, b),
            );
            setCategories(filteredCats);
            filteredCats.forEach((element) => {
              subcatList = subcatList.concat(element.Subcategories);
            });
          } else {
            setCategories(data.sort((a, b) => alphabeticSort(a, b)));
          }
          if (category.length > 0) {
            subcatList = [];
            const ides = category.map((cat) => cat.id);
            data.forEach((element) => {
              if (ides.includes(element.id)) {
                subcatList = subcatList.concat(element.Subcategories);
              }
            });
          } else {
            data.forEach((element) => {
              subcatList = subcatList.concat(element.Subcategories);
            });
          }
          setSubcategories(subcatList.sort((a, b) => alphabeticSort(a, b)));
        } else {
          enqueueSnackbar('Error al cargar la información de las categorías.', {
            variant: 'error',
          });
        }
      };
      getCategories();
    } catch {
      setCategories([]);
    }
  }, [account]);

  const getData = async () => {
    try {
      setLoading(true);
      const subIdes = subcategory.map((val) => val.id);
      const catIdes = category.map((val) => val.id);
      const supIdes = supermarket.map((val) => val.id);
      const brandIdes = brand.map((val) => val.id);
      const userRole = account.user.role;
      const userId = account.user.id;

      const response = await getProductSnapshotsPerPage(
        page, limit, query, order,
        startDate, endDate, supIdes,
        catIdes, subIdes, brandIdes, userRole,
        userId,
      );

      if (response.success) {
        const data = await response?.data?.data;
        const arrayData = data?.productsnapshots ? Object.values(data?.productsnapshots) : null;
        setProductsnapshots(arrayData);
        setCount(data?.totalProductsnapshots);
      } else {
        enqueueSnackbar('Error al cargar la información de los productos.', {
          variant: 'error',
        });
      }
    } catch (error) {
      setProductsnapshots([]);
      enqueueSnackbar('Error al procesar la solicitud.', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [page, limit, query, order,
    startDate, endDate, supermarket,
    category, subcategory, brand, account]);

  // if (loading) {
  //   return (<CircularProgress style={{ color: 'var(--brand-blue' }} />);
  // }
  return (
    <Page
      className={classes.root}
      title="FH Engel | Capturas"
    >
      <Container maxWidth={false}>
        <Header
          cleanFilters={cleanFilters}
        />
          <Box mt={3}>
            <Results
              sortOptions={sortOptions}
              count={count}
              supermarkets={supermarkets}
              originalCategories={originalCategories}
              categories={categories}
              brands={brands}
              setCategories={setCategories}
              subcategories={subcategories}
              setSubcategories={setSubcategories}
              productsnapshots={productsnapshots}
              loading={loading}
            />
          </Box>
      </Container>
    </Page>
  );
}

export default ProductsnapshotListView;
